<template>
  <div>
    <!-- If user is authenticated, show link to profile dashboard -->
    <NuxtLink
      v-if="xIsAuthenticated"
      class="flex flex-col items-center gap-2 min-w-[67px]"
      to="/profile/dashboard"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.9995 12C22.9995 18.0751 18.0746 23 11.9995 23C5.92438 23 0.999512 18.0751 0.999512 12C0.999512 5.92487 5.92438 1 11.9995 1C18.0746 1 22.9995 5.92487 22.9995 12Z"
          stroke="#9A9A9A"
          stroke-width="2"
        />
        <path
          d="M12.0273 12.12C13.7173 12.12 15.0873 10.75 15.0873 9.06C15.0873 7.37001 13.7173 6 12.0273 6C10.3373 6 8.96729 7.37001 8.96729 9.06C8.96729 10.75 10.3373 12.12 12.0273 12.12Z"
          stroke="#9A9A9A"
          stroke-width="2"
        />
        <path
          d="M6.99951 18.4199L7.28386 16.6492C7.56416 14.9036 9.07043 13.6199 10.8383 13.6199H13.1962C14.9318 13.6199 16.4202 14.8582 16.7361 16.5647L17.0795 18.4199"
          stroke="#9A9A9A"
          stroke-width="2"
        />
      </svg>
      <span class="uppercase text-[8px] font-alt font-medium text-[#4F5162]"> Account </span>
    </NuxtLink>
    <!-- If user is not authenticated, show login button -->
    <button
      v-else
      @click="xOpenLoginPopup"
      class="flex flex-col items-center gap-2 min-w-[67px] cursor-pointer"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.9995 12C22.9995 18.0751 18.0746 23 11.9995 23C5.92438 23 0.999512 18.0751 0.999512 12C0.999512 5.92487 5.92438 1 11.9995 1C18.0746 1 22.9995 5.92487 22.9995 12Z"
          stroke="#9A9A9A"
          stroke-width="2"
        />
        <path
          d="M12.0273 12.12C13.7173 12.12 15.0873 10.75 15.0873 9.06C15.0873 7.37001 13.7173 6 12.0273 6C10.3373 6 8.96729 7.37001 8.96729 9.06C8.96729 10.75 10.3373 12.12 12.0273 12.12Z"
          stroke="#9A9A9A"
          stroke-width="2"
        />
        <path
          d="M6.99951 18.4199L7.28386 16.6492C7.56416 14.9036 9.07043 13.6199 10.8383 13.6199H13.1962C14.9318 13.6199 16.4202 14.8582 16.7361 16.5647L17.0795 18.4199"
          stroke="#9A9A9A"
          stroke-width="2"
        />
      </svg>
      <span class="uppercase text-[8px] font-alt font-medium text-[#4F5162] flex flex-col items-center justify-center">
        Account
      </span>
    </button>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        xIsAuthenticated: 'authuser/xIsAuthenticated'
      })
    },
    methods: {
      ...mapActions({
        xOpenLoginPopup: 'auth/xOpenLoginPopup'
      })
    }
  }
</script>
