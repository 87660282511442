<template>
  <NuxtLink
    class="flex flex-col items-center gap-2 min-w-[67px]"
    to="/shop"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3543 7.15251L15.3479 7.15247M18.5229 2.35142L12.7226 1.90523C12.2083 1.86568 11.7024 2.05281 11.3377 2.41751L1.91787 11.8374C1.2279 12.5274 1.2279 13.646 1.91787 14.336L8.16441 20.5825C8.85439 21.2725 9.97306 21.2725 10.663 20.5825L20.0829 11.1627C20.4476 10.798 20.6347 10.2921 20.5952 9.77786L20.149 3.9775C20.0822 3.10862 19.3918 2.41825 18.5229 2.35142Z"
        stroke="#838383"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="uppercase text-[8px] font-alt font-medium text-[#4F5162]"> Shop </span>
  </NuxtLink>
</template>
