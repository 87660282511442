<template>
  <div
    class="fixed bottom-0 left-0 h-20 px-4 bg-white w-full z-[29] sm:hidden bg-white/80 backdrop-blur-md mobiloud-menu hidden"
    :class="{
      'mobile-menu-hidden': isHidden,
      'mobile-menu-visible': !isHidden,
      '': insideMenu
    }"
  >
    <nav class="flex justify-between items-center w-full relative">
      <MobileMenuButton
        :inside-menu="insideMenu"
        @menuToggled="toggleMenu"
      />
      <MobileBrandButton />
      <RenderlessXCart v-slot="{ xShoppingCart }">
        <NuxtLink
          :to="'/cart'"
          class="text-white relative top-[-16px]"
        >
          <img
            src="@/assets/icon/svg/mobile-cart.svg?url"
            alt="View your shopping cart"
            class="w-[70px] h-[70px]"
          />
          <div
            class="rounded-full p-0.5 absolute top-4 right-4 min-w-[15px] min-h-[15px] bg-pink leading-none text-center text-xs text-black"
          >
            {{ xShoppingCart.totalQuantity }}
          </div>
        </NuxtLink>
      </RenderlessXCart>
      <MobileMenuSpecialButton />
      <MobileAccountButton />
    </nav>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { ref } from 'vue'

  const store = useStore()
  const isOptionSelected = () => store.getters['xIsSelectedOptionsStatus']
  const xIsNavbarOpen = () => computed(() => store.getters['xIsNavbarOpen'])

  const isHidden = ref(false)

  defineProps({
    insideMenu: {
      type: Boolean,
      default: false
    }
  })

  watch(isOptionSelected, (newValue) => {
    console.log('Option Selected:', newValue)
    isHidden.value = newValue
  })

  const toggleMenu = () => {
    store.dispatch('xToggleMobileMenu')
  }
</script>

<style scoped lang="postcss">
  .mobile-menu-hidden {
    transition: all 0.5s ease-in-out 0s;
    transform: translate3d(0, 200%, 0);
  }
  .mobile-menu-visible {
    transition: all 0.5s ease-in-out 0s;
    transform: translate3d(0, 0%, 0);
  }
</style>
