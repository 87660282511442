<template>
  <NuxtLink
    class="flex flex-col items-center gap-2 min-w-[67px]"
    to="/"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.80625 2.70641C3.70639 1.80655 4.92707 1.30104 6.19985 1.30104C7.47264 1.30104 8.69332 1.80655 9.59345 2.70641L10.9999 4.11161L12.4063 2.70641C12.849 2.24796 13.3787 1.88229 13.9643 1.63072C14.5499 1.37916 15.1798 1.24675 15.8171 1.24121C16.4545 1.23567 17.0865 1.35712 17.6764 1.59847C18.2663 1.83982 18.8023 2.19623 19.2529 2.64691C19.7036 3.0976 20.06 3.63353 20.3014 4.22343C20.5427 4.81333 20.6642 5.4454 20.6587 6.08274C20.6531 6.72008 20.5207 7.34993 20.2691 7.93555C20.0176 8.52117 19.6519 9.05083 19.1934 9.49361L10.9999 17.6884L2.80625 9.49361C1.90639 8.59348 1.40088 7.3728 1.40088 6.10001C1.40088 4.82722 1.90639 3.60654 2.80625 2.70641V2.70641Z"
        stroke="#9A9A9A"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
    <span class="uppercase text-[8px] font-alt font-medium text-[#4F5162]"> Specials </span>
  </NuxtLink>
</template>
